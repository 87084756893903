import React from "react";
import logo from "../../../../assets/images/logos/logo-three.png";

export default function FooterHomeSix({t}) {
  return (
    <footer className="main-footer footer-five bg-lighter pt-110">
      <div className="container" style={{paddingTop: '60px'}}>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="footer-widget about-widget">
              <div className="footer-logo mb-35">
                <a href="/">
                  <img src={logo} alt="Logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="footer-widget contact-widget mr-30 rmr-0">
              <h4 className="footer-title">{t('feedback')}</h4>
              <ul className="list-style-two">
                <li>
                  <i className="fas fa-phone-alt"></i>{" "}
                  <a href={t('phoneLink')}>{t('phone')}</a>
                </li>
                <li>
                  <i className="fas fa-envelope"></i>{" "}
                  <a href={t('emailLink')}>{t('email')}</a>
                </li>
                <li>
                  <i className="fas fa-map-marker-alt"></i> {t('location')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-inner justify-content-center">
            <p>2023 All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
