import { Route, Routes } from "react-router-dom";
import HomeSix from "./components/HomeSix";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeSix />} />
      <Route exact path="*" element={<HomeSix />} />
    </Routes>
  );
}
