import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const languages = [
  { code: "en", native: "English" },
  { code: "ru", native: "Russian" },
];
i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
          contactUs: "Contact us",
          contact: "Contacts",
          email: "sales@tocloud.kz",
          emailLink:"mailto:sales@tocloud.kz",
          phone:"+ 7 (705) 171 9716",
          phoneLink:"callto:+77051719716",
          location: "г. Астана ул. Т. Жургенова 18/2 НП 26",
          main: "Main",
          workDays:"Mon-Fri",
          workHours:"Work hours",
          error:"Oops, error!",
          sending:"Sending...!",
          sent:"Email sent!",

          mainSubtitle: "Communication Security Systems",
          feedback:"Feedback",
          aboutUs: "About us",
          consulting: "Consultation",
          freeConsulting: "Get a free consultation right now!",
          yourName:"Your name",
          surnamePlaceholder:"Smith John",
          yourEmail:"Your Email address",
          yourEmailPlaceholder: "example@domain.org",
          phoneNumber:"Phone number",
          callMeBack:"Call me back!",
          contactUs_2:"Contact us",
          emailUS:"Email us",

          functions:"Functions",
          flexibility:"Flexibility",
          emergencyButton:"Emergency button",
          knowMore: "Learn more",
          cubaAboutSmartButton: "A Kazakhstan-developed system designed for immediate notification of security threats in a building",
          cubaAboutUsVideo: "Video about Cuba",
          servicesProtectYourSelf: "Protect yourself",
          servicesProtectYourInfrastructure: "and your infrastructure",
          servicesPart1: "Reducing the number of casualties",
          servicesPart2: "Reducing response time to a crisis situation",
          servicesPart3: "Minimizing property damage",
          servicesPart4: "Immediate notification to the security service",

          aboutSectionMain: "Report incidents to all the platforms necessary for you",
          aboutSectionPart1Title: "By Ppressing or disabling the button",
          aboutSectionPart1Subtitle: "Automatically send notifications to various platforms and response centers",
          aboutSectionPart2Title: "Supporting all types of integrations",
          aboutSectionPart2Subtitle: "Easy integration into existing and custom notification platforms",

          customizeSection: "Direction",
          customizeSectionTitle: "Our solution fits",
          customizeSection1: "Business centers and offices",
          customizeSection2: "Schools and educational facilities",
          customizeSection3: "Concert halls",
          customizeSection4: "Sports stadiums",

          counterSection1: "Independent alarm events",
          counterSection2: "Notification parameters",
          counterSection3: "Self-diagnostics in minutes",
          counterSection4: "One-time notification systems",
          
          callToAction:"Protect Yourself and Your Infrastructure!",

          leaveMessage: "Leave a message",
          sendMessage: "Send message",
          yourMessage: "Your message",
          pleaseMessage: "Please, send any question you have, and we will definitely call you back!",        
        
          footerAgitation: "We look forward to a productive collaboration with you!",
          footerAgitation2: "Join the ranks of our happy and successful clients!"
        }
      },
      ru: {
        translation: {
          contactUs: "Связаться с нами",
          contact: "Контакты",
          email: "sales@tocloud.kz",
          emailLink:"mailto:sales@tocloud.kz",
          phone:"+ 7 (705) 171 9716",
          phoneLink:"callto:+77051719716",
          location: "г. Астана ул. Т. Жургенова 18/2 НП 26",
          main: "Главная",
          workDays:"пн. – пт.",
          workHours:"Рабочие часы",
          error:"Oops, ошибка!",
          sending:"Отправляется...",
          sent:"Сообщение отправлено!",


          mainSubtitle: "Системы Связи и Безопасности",
          feedback:"Обратная связь",
          aboutUs: "О нас",
          consulting: "Консультация",
          freeConsulting: "Получите бесплатную консультацию!",
          yourName:"Ваше имя",
          surnamePlaceholder:"Исаев Амин",
          yourEmail:"Ваш Email адрес",
          yourEmailPlaceholder: "example@domain.org",
          phoneNumber:"Номер телефона",
          callMeBack:"Перезвонить мне!",
          contactUs_2:"Позвоните нам",
          emailUS:"Напишите нам",
          
          
          functions:"Функции",
          flexibility:"Гибкость",
          emergencyButton:"Тревожная Кнопка",
          knowMore:"Подробнее",
          cubaAboutSmartButton:"Система Казахстанской разработки, предназначенная для мгновенного уведомления о наличии угрозы безопасности в здании",
          cubaAboutUsVideo:"Видео о Cuba",
          
          servicesProtectYourSelf:"Обезопасьте себя",
          servicesProtectYourInfrastructure:"и свою инфраструктуру",
          servicesPart1:"Уменьшение количества жертв",
          servicesPart2:"Сокращение времени реагирования на кризисную ситуацию",
          servicesPart3:"Минимизация ущерба для имущества",
          servicesPart4:"Немедленное оповещение службы безопасности",

          aboutSectionMain:" Сообщайте об инциденте во все необходимые для Вас платформы",
          aboutSectionPart1Title:"При нажатии или отключении кнопки",
          aboutSectionPart1Subtitle:"Автоматическая отправка уведомления в различные платформы и центры реагирования",
          aboutSectionPart2Title:"Поддерживаем все виды интеграций",
          aboutSectionPart2Subtitle:"Легкая интеграция в уже существующие и собственные платформы оповещения",

          customizeSection:"Направление",
          customizeSectionTitle:"Наше решение подходит",
          customizeSection1:"Бизнес-центрам и офисам",
          customizeSection2:"Школам и объектам образования",
          customizeSection3:"Концертным залам", 
          customizeSection4:"Спортивным стадионам",

          counterSection1:"Независимых тревожных событий",
          counterSection2:"Параметров оповещения",
          counterSection3:"Самодиагностик в минуту",
          counterSection4:"Единовременных систем оповещения",

          callToAction:"Обезопасьте себя и свою инфраструктуру!",

          leaveMessage:"Оставьте сообщение",
          sendMessage:"Отправить сообщение",
          yourMessage:"Ваше сообщение",
          pleaseMessage:"Пожалуйста, отправьте любой интересующий Вас вопрос и мы Вам обязательно перезвоним!",

          footerAgitation:"Будем рады продуктивному сотрудничеству с Вами!",
          footerAgitation2:"Вступайте в ряды наших счастливых и успешных Клиентов!",
        }
      },
      kz: {
        translation: {
          contactUs: "Бізбен хабарласу",
          contact: "Байланыс",
          email: "sales@tocloud.kz",
          emailLink:"mailto:sales@tocloud.kz",
          phone:"+ 7 (705) 171 9716",
          phoneLink:"callto:+77051719716",
          location: "Астана қаласы, Темірбек Жүргенов көшесі 18/2 НП 26",
          main: "Басты Бет",
          workDays: "дс. - жм.",
          workHours: "Жұмыс уақыты",
          error:"Oops, Қате!",
          sending:"Жіберіледі...",
          sent:"Хабарлама жіберілді!",

          mainSubtitle: "Байланыс және қауіпсіздік жүйелері",
          feedback: "Кері байланыс",
          aboutUs: "Біз туралы",
          consulting: "Кеңес беру",
          freeConsulting: "Тегін кеңес алыңыз!",
          yourName: "Сіздің Атыңыз",
          surnamePlaceholder: "Исаев Амин",
          yourEmail:"Сіздің email",
          yourEmailPlaceholder: "example@domain.org",
          phoneNumber: "Телефон нөмірі",
          callMeBack: "Маған қайтадан қоңырау шалыңыз!",
          contactUs_2: "Бізге хабарласыңыз",
          emailUS: "Бізге хат жазыңыз",

          functions:"Мүмкіндіктер",
          flexibility:"Икемділік",
          emergencyButton:"Дабыл түймесі",
          knowMore: "Толығырақ білу",
          cubaAboutSmartButton: "Ғимаратта қауіп-қатер бар екендігі туралы дереу хабарлауға арналған Қазақстандық өнім",
          cubaAboutUsVideo: "Cuba туралы бейне",
          servicesProtectYourSelf: "Өзіңіздің және инфрақұрылымыңыздың қауіпсіздігін",
          servicesProtectYourInfrastructure: "Қамтамасыз етіңіз",
          servicesPart1: "Жәбірленушілер санын азайту",
          servicesPart2: "Дағдарысқа жауап беру уақытын қысқарту",
          servicesPart3: "Мүлікке келтірілген зиянды азайту",
          servicesPart4: "Қауіпсіздік қызметтерін дереу ескерту",

          aboutSectionMain: "Жедел оқиға туралы ақпаратты Сізге қажетті барлық платформаларға жіберіңіз",
          aboutSectionPart1Title: "Түймені басқан немесе өшірген кезде",
          aboutSectionPart1Subtitle: "Хабарламаны әртүрлі жауап беру платформалары мен орталықтарына автоматты түрде жіберу",
          aboutSectionPart2Title: "Интеграцияның барлық түрлерін қолдаймыз",
          aboutSectionPart2Subtitle: "Бұрыннан бар және меншікті ескерту платформаларына оңай интеграция",

          customizeSection: "Бағыт",
          customizeSectionTitle: "Біздің шешімдеріміз сәйкес келеді",
          customizeSection1: "Бизнес орталықтар мен кеңселерге",
          customizeSection2: "Мектептер мен білім беру объектілеріне",
          customizeSection3: "Концерт залдарына",
          customizeSection4: "Спорттық стадиондарға",

          counterSection1: "Тәуелсіз дабыл оқиғалары",
          counterSection2: "Ескерту параметрлері",
          counterSection3: "Минутына өзін-өзі диагностикалау",
          counterSection4: "Бір реттік ескерту жүйелері",
          
          callToAction:"Өзіңізді және инфрақұрылымыңызды қорғаңыз!",

          leaveMessage: "Хабарлама қалдыру",
          sendMessage: "Хабарлама жіберу",
          yourMessage: "Сіздің хабарламаңыз",
          pleaseMessage: "Сізді қызықтыратын кез келген сұрақты жіберіңіз, сізге қайта қоңырау шаламыз!",

          footerAgitation: " Сізді қызықтыратын кез-келген сұрақты жіберіңіз, біз сізге міндетті түрде қоңырау шаламыз!",
          footerAgitation2: " Біздің бақытты және табысты клиенттеріміздің қатарына қосылыңыз!",
        }
      }
    }
  });

export default i18n;