import React from "react";
import thumb1 from "../../assets/images/cuba/stadium.png";
import thumb2 from "../../assets/images/cuba/school.png";

export default function CustomizeSection({t, scrollRef, onClick}) {
  const handleClick = () => {
    onClick();
  };
  return (
    <section ref={scrollRef} className="customization-section py-75 rpy-60">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="customization-images wow fadeInLeft delay-0-2s">
              <img src={thumb1} alt="customization" />
              <img src={thumb2} alt="customization" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="customization-content mr-100 rmr-0 pb-35 rpb-40 rpt-50 wow fadeInRight delay-0-2s">
              <div className="section-title mb-25">
                <span className="sub-title">{t('customizeSection')}</span>
                <h2>{t('customizeSectionTitle')}</h2>
              </div>
              <ul className="list-style-three mt-40 pb-10">
                <li>{t('customizeSection1')}</li>
                <li>{t('customizeSection2')}</li>
                <li>{t('customizeSection3')}</li>
                <li>{t('customizeSection4')}</li>
                
              </ul>
              <a onClick={handleClick} className="theme-btn mt-15" style={{width: '220px'}}>
                {t('contactUs')} <i className="fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
