import React from "react";

export default function OurServices({t}) {
  return (
    <section className="what-we-do-two text-center pt-115 rpt-95 pb-90 rpb-70">
      <div className="container">
        <div className="section-title mb-55">
          <span className="sub-title">{t('functions')}</span>
          <h2>
            {t('servicesProtectYourSelf')} <br />
            {t('servicesProtectYourInfrastructure')}
          </h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two wow fadeInUp delay-0-2s" style={{height: '280px'}}>
              <div className="icon">
                <i className="flaticon flaticon-web-traffic-1"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar"></span>
              </div>
              <h5 >
                <a>{t('servicesPart1')}</a>
              </h5>
              <p></p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-two wow fadeInUp delay-0-4s" style={{height: '280px'}}>
              <div className="icon">
                <i className="flaticon flaticon-startup"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar delay-1-0s"></span>
              </div>
              <h5>
                <a>{t('servicesPart2')}</a>
              </h5>
              <p></p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-three wow fadeInUp delay-0-6s" style={{height: '280px'}}>
              <div className="icon">
                <i className="flaticon flaticon-money"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar delay-2-0s"></span>
              </div>
              <h5>
                <a>{t('servicesPart3')}</a>
              </h5>
              <p></p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-four wow fadeInUp delay-0-8s" style={{height: '280px'}}>
              <div className="icon">
                <i className="flaticon flaticon-chat"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar delay-0-5s"></span>
              </div>
              <h5>
                <a>{t('servicesPart4')}</a>
              </h5>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
