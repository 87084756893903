import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { langToggle } from "../../store/langSetting";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeSix from "../partials/Footers/FooterHomeSix";
import HeaderHomeSix from "../partials/Headers/HeaderHomeSix/index";
import AboutSection from "./AboutSection";
import CallToActionSection from "./CallToActionSection";
import CounterSection from "./CounterSection";
import CustomizeSection from "./CustomizeSection";
import Hero from "./Hero";
import OurServices from "./OurServices";
import GetInTouchSection from "./GetInTouchSection";
// import TestimonialSection from './TestimonialSection'

export default function HomeSix() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const scrollAbout = useRef();
  const { t, i18n } = useTranslation();
  console.log(t)
  const handleTrans = (code) => {
    i18n.changeLanguage(code);
    dispatch(langToggle(code));
  };
  const scrollToRef = () => {
    window.scrollTo({
      top: scrollRef.current.offsetTop,
      behavior: 'smooth'
    });
  };
  const scrollToAbout = () => {
    window.scrollTo({
      top: scrollAbout.current.offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <HeaderHomeSix t={t} handleTrans={handleTrans} onClick={scrollToRef}/>
      <Hero t={t} onClick={scrollToAbout}/>
      <OurServices t={t}/>
      <AboutSection t={t} />
      <CallToActionSection t={t} onClick={scrollToRef}/>
      <CustomizeSection t={t} scrollRef={scrollAbout} onClick={scrollToRef}/>
      <CounterSection t={t}/>
      {/* <TestimonialSection/> */}
      <GetInTouchSection t={t} scrollRef={scrollRef}/>
      <FooterHomeSix t={t}/>
      <BacktoTopCom />
    </>
  );
}
