import React, {useState} from "react";
import axios from 'axios';
import background from "../../assets/images/cuba/contactus.jpeg";


export default function GetInTouchSection({t, scrollRef}) {
  const [formPressed, setFormPressed] = useState(false);
  const [formSuccess, setFormSuccess] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [buttonText, setButtonText] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if ((name && phone.length > 6) || (name && email)){
      setFormPressed(true)
      setButtonText(t('sending'))
      const sendForm = {
        "fields":
          { 
            "TITLE": "Онлайн Заявка Cuba SmartButton", 
            "SECOND_NAME":"CUBA SmartButton",
            "TYPE_ID": "SERVICES",
            "STAGE_ID": "C43:NEW",
            "PROBABILITY": null,
            "CURRENCY_ID": "KZT",
            "OPPORTUNITY": "0.00",
            "IS_MANUAL_OPPORTUNITY": "N",
            "TAX_VALUE": null,
            "LEAD_ID": "2329",
            "QUOTE_ID": null,
            "ASSIGNED_BY_ID": "733",
            "CATEGORY_ID": "43",
            "STAGE_SEMANTIC_ID": "P",
            "COMMENTS": `Пользователь ${name} оставил Заявку на сайте Button.tocloud.kz, <br/> Контактные данные: <br/> Имя: ${name} <br/> Телефон: ${phone} <br/> Email: ${email} <br/> Комментарий: ${message}`
          },
        "params": {}
      }
      axios.post('https://toovehikz.bitrix24.kz/rest/651/hjunevomrceun1v1/crm.deal.add.json', sendForm)
      .then( response => {
            console.log(response)
            setFormSuccess(true)
            setButtonText(t('sent'))
            setTimeout(() => {
            setButtonText(t('sendMessage'))
            setFormPressed(false)
            setFormSuccess(null)
          },5000)
        })
      .catch(err => {
        console.log(err)
          setFormSuccess(false)
          setButtonText(t('error'))
          setTimeout(() => {
          setButtonText(t('sendMessage'))
          setFormPressed(false)
          setFormSuccess(null)
        },5000)
      }) 
      
      
    }
  };
  const buttonStatus = formSuccess === true ?"theme-btn-new": formSuccess === false ?"theme-btn-new-red":"theme-btn"; //eslint-disable-line
  return (
    <section ref={scrollRef} className="contact-two ">
      <div className="container">
        <div className="contact-section-inner p-50 br-5 bg-white">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-section-form rmb-50 wow fadeInLeft delay-0-1s">
                <div className="section-title mb-20">
                  <h2>{t('contactUs')}</h2>
                </div>
                <form
                  id="contact-form"
                  className="contact-form"
                  name="contact-form"
                  action="#"
                  method="post"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">{t('yourName')}</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          placeholder={t('surnamePlaceholder')}
                          required
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="number">{t('phoneNumber')}</label>
                        <input
                          type="text"
                          id="number"
                          name="number"
                          className="form-control"
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          placeholder="+7 777 123 45 67"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email">{t('yourEmail')}</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          placeholder={t('yourEmailPlaceholder')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          className="form-control"
                          rows="4"
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                          placeholder={t('pleaseMessage')}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button onClick={handleSubmit} disabled={formPressed} type="submit" className={buttonStatus}> 
                        {buttonText || t('sendMessage')} 
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="contact-image-number style-two bgs-cover overlay wow fadeInRight delay-0-2s"
                style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>{t('freeConsulting')}</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>{t('contactUs_2')}</span>
                        <h5>
                          <a href={t('phoneLink')}>{t('phone')}</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>{t('emailUS')}</span>
                        <h5>
                          <a href={t('emailLink')}>{t('email')}</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>{t('workHours')}</span>
                        <h5>{t('workDays')} 9:00 - 18:00</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
