import React from "react";
import CounterUp from "../helpers/CounterUp";

export default function CounterSection({t}) {
  return (
    <section
      id="cournter-section-three"
      className="cournter-section-three bg-lighter text-center pt-115 rpt-95 pb-70 rpb-50"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="success-item circle-style wow fadeInUp delay-0-2s">
              <span className="count-text plus" data-speed="5000" data-stop="35">
                <CounterUp
                  endValue="4"
                  sectionSelect="cournter-section-three"
                />
              </span>
              <span>{t('counterSection1')}</span>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="success-item circle-style wow fadeInUp delay-0-4s color-two">
              <span className="count-text plus" data-speed="50" data-stop="10">
                <CounterUp
                  endValue="10"
                  sectionSelect="cournter-section-three"
                />
              </span>
              <span>{t('counterSection2')}</span>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="success-item circle-style wow fadeInUp delay-0-6s color-three">
              <span
                className="count-text"
                data-speed="50"
                data-stop="8"
              >
                <CounterUp
                  endValue="8"
                  sectionSelect="cournter-section-three"
                />
              </span>
              <span>{t('counterSection3')}</span>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="success-item circle-style wow fadeInUp delay-0-8s color-four">
              <span
                className="count-text plus"
                data-speed="20"
                data-stop="4"
              >
                <CounterUp
                  endValue="4"
                  sectionSelect="cournter-section-three"
                />
              </span>
              <span>{t('counterSection4')}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
