import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Flag from 'react-world-flags'
import logo from "../../../../assets/images/logos/logo-three.png";
import logo2 from "../../../../assets/images/logos/logo-two.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";


export default function HeaderHomeSix({t, handleTrans, onClick}) {
  const lang = useSelector((state) => state.lang.lang);
  useEffect(() => {
    StickyMenu(".main-header");
  });
  const handleClick = () => {
    onClick();
  };
  return (
    <>
      <MobileHeaderCom logo={logo2} translate={t} handleTrans={handleTrans}/>
      <header className="main-header header-six">
        <div className="header-upper">
          <div className="container clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Logo" title="Logo" />
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <nav className="main-menu navbar-expand-lg">
                  <Navigation className="nav-home-six" />
                </nav>
                <div className="menu-btn">
                  <a style={{padding: '2px'}} type="button" onClick={() => {handleTrans("kz")}}><span className={lang === "kz" ? "emoji-colored":"emoji-grey"} role="img" aria-label="kz"><Flag code="kz" height="16" /></span></a>
                  <a style={{padding: '2px'}} type="button" onClick={() => {handleTrans("ru")}}><span className={lang === "ru" ? "emoji-colored":"emoji-grey"} role="img" aria-label="ru"><Flag code="ru" height="16" /></span></a>
                  <a style={{padding: '2px'}} type="button" onClick={() => {handleTrans("en")}}><span className={lang === "en" ? "emoji-colored":"emoji-grey"} role="img" aria-label="eng"><Flag code="us" height="16" /></span></a>
                </div>
                <div className="menu-btn">
                  <a  onClick={handleClick} className="theme-btn style-seven" >
                  {t("contactUs")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
